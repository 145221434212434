import { NextPageContext } from "next";
import NextErrorComponent, { ErrorProps as NextErrorProps } from "next/error";
import { NextSeo } from "next-seo";

import * as Sentry from "@sentry/nextjs";
import { ReactElement } from "react";
import {
  Container,
  ContainerSize,
  Section,
  TextContainer,
  TextContainerAlign,
} from "layout";
import { Button } from "elements";

const getContent = ({ statusCode }) => {
  switch (statusCode) {
    case 401:
      return "It looks like you're not supposed to be here 👀";
    case 404:
      return "We could not find the page you were looking for 🛰";
    case 500:
      return "Our server had some trouble processing that request 🔥";
    default:
      return "Even we don't know what happened 🤯";
  }
};

type ErrorPageProps = {
  err?: Error;
  statusCode: number;
  hasGetInitialPropsRun?: boolean;
  children?: ReactElement;
};

type ErrorProps = {
  hasGetInitialPropsRun: boolean;
} & NextErrorProps;

function ErrorPage({ statusCode, hasGetInitialPropsRun, err }: ErrorPageProps) {
  const content = getContent({ statusCode });

  if (!hasGetInitialPropsRun && err) {
    Sentry.captureException(err);
  }

  return (
    <main>
      <NextSeo title={`${statusCode}`} description={content} />
      <Section className="min-h-[400px] sm:h-screen flex items-center justify-center relative">
        <Container size={ContainerSize.Sm}>
          <TextContainer
            align={TextContainerAlign.Center}
            className="flex flex-col items-center justify-center space-y-md"
          >
            <h1>{content}</h1>
            <div>
              <Button.Link href="/">{"Go back to home"}</Button.Link>
            </div>
          </TextContainer>
        </Container>
      </Section>
    </main>
  );
}

ErrorPage.getInitialProps = async ({ res, err, asPath }: NextPageContext) => {
  const errorInitialProps = (await NextErrorComponent.getInitialProps({
    res,
    err,
  } as NextPageContext)) as ErrorProps;

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true;

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html
  if (err) {
    Sentry.captureException(err);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000);

    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`)
  );

  await Sentry.flush(2000);

  return errorInitialProps;
};

export default ErrorPage;
